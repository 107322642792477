import React from "react";

const Decor = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="115.732"
      height="16"
      viewBox="0 0 115.732 16"
    >
      <g
        id="Group_234"
        data-name="Group 234"
        transform="translate(-902.134 -162.375)"
      >
        <path
          id="Path_411"
          data-name="Path 411"
          d="M1017.866,166.679c-5.983,2.159-11.423,4.121-19.678,4.073-21.016-.046-42.586-15.041-60.7-4.8,22.823-3.583,38.47,11.273,60.931,8.827A37.036,37.036,0,0,0,1017.866,166.679Z"
          fill="#bda472"
        />
        <path
          id="Path_412"
          data-name="Path 412"
          d="M980.873,175.426c-17.878.764-30.023-3.219-43.626-8.044-11.62-3.333-25.952-4.64-35.113,3.29,9.582-3.6,20.059-2.316,28.837.17C938.977,173.365,960.367,183.228,980.873,175.426Z"
          fill="#bda472"
        />
      </g>
    </svg>
  );
};

export default Decor;
