import "./styles.scss";

import React from "react";

const mainClass = "home-pros";

const Pros = ({ data, title, text }) => {
  return (
    <section className={mainClass}>
      <div className="container">
        <div className={`${mainClass}__content`}>
          <div className={`${mainClass}__heading`}>
            <h2
              className={`${mainClass}__heading-title`}
              dangerouslySetInnerHTML={{
                __html: title,
              }}
            />
            <span
              className={`${mainClass}__heading-text`}
              dangerouslySetInnerHTML={{
                __html: text,
              }}
            />
          </div>
          <ul className={`${mainClass}__pros`}>
            {data?.map(({ prosItemDesc, prosItemImage }, index) => {
              return (
                <li key={index} className={`${mainClass}__pros-item`}>
                  <div className={`${mainClass}__pros-image`}>
                    <img
                      src={prosItemImage?.sourceUrl}
                      alt={prosItemImage?.altText}
                    />
                  </div>
                  <p className={`${mainClass}__pros-title`}>{prosItemDesc}</p>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Pros;
