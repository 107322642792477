import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";

import Layout from "components/Layout";
import ContactModal from "components/ContactModal";
import {
  Hero,
  FulfillForm,
  ForestView,
  Luxury,
  CityOfStars,
  Pros,
  ProsTooltips,
  DeerDesign,
  Location,
  Gallery,
  BusinessModel,
  Partners,
} from "page_components/home";
import ContactSection from "components/ContactSection";

import scrollToElement from "utils/scrollToElement";

const Home = ({ location, data }) => {
  const { hash, pathname, search } = location;
  const [init_loading, setInitLoading] = useState(false);
  const [contact_modal_open, setContactModalOpen] = useState(null);

  const acfHome = data?.homePage?.nodes?.[0]?.acfHome;

  useEffect(() => {
    setInitLoading(true);
  }, [hash]);

  useEffect(() => {
    if (!!!hash) return;

    if (!!init_loading) {
      scrollToElement(`${hash.split("?")[0]}-section`);
    } else {
      setTimeout(() => {
        scrollToElement(`${hash.split("?")[0]}-section`);
      }, 100);
    }

    setInitLoading(true);

    // eslint-disable-next-line
  }, [location]);

  return (
    <Layout
      seo={{
        title: "Forest Międzyzdroje - apartamenty nad morzem na sprzedaż",
        description:
          "Forest Międzyzdroje to inwestycja condo nad morzem oferująca apartamenty inwestycyjne na sprzedaż. Kup i zarabiaj na własnej nieruchomości nad Bałtykiem.",
        keywords:
          "Apartamenty na sprzedaż nad morzem w Międzyzdrojach, Luksusowe apartamenty nad Bałtykiem na sprzedaż, Luksusowe apartamenty inwestycyjne w otulinie lasu nad morzem!, Apartamenty inwestycyjne w Międzyzdrojach, Zainwestuj w luksusowy apartament nad morzem, Zainwestuj w apartament w Międzyzdrojach, Zainwestuj w apartamenty blisko Alei Gwiazd, Zainwestuj we własny apartament nad morzem, Zainwestuj w apartament nad morzem i zarabiaj!, Kup apartament w luksusowym obiekcie nad Morzem Bałtyckim, Kup luksusowy apartament w Międzyzdrojach, Kup apartament nad morzem w otulinie parku narodowego, Kup apartament w Międzyzdrojach i zarabiaj na wynajmie!, Apartamenty condo na sprzedaż w Międzyzdrojach, Zainwestuj w apartament condo nad morzem, zarabiaj i wypoczywaj, Kup apartament condo nad morzem i zarabiaj na wynajmie, Inwestuj w apartamenty w Międzyzdrojach, Unikatowa, luksusowa inwestycja w samym sercu lasu w Międzyzdrojach",
      }}
      location={{ pathname, hash }}
      search={search}
      apartments={[]}
      setContactModalOpen={setContactModalOpen}
      popup={acfHome?.popup?.sourceUrl}
      popupLink={acfHome?.popupLink}
    >
      <Hero data={acfHome?.slider} />
      <FulfillForm
        search={search}
        investment={contact_modal_open?.investment}
      />
      <ForestView data={acfHome} location={location} />
      <Luxury data={acfHome?.luxTails} />
      <CityOfStars title={acfHome?.luxTitle} text={acfHome?.luxText} />
      <Pros
        data={acfHome?.proTails}
        title={acfHome?.prosTitle}
        text={acfHome?.proText}
      />
      <ProsTooltips data={acfHome?.proTails} />
      <DeerDesign
        left_image={acfHome?.deerLeftImage?.sourceUrl}
        right_image={acfHome?.deerRightImage?.sourceUrl}
        header={acfHome?.deerHeader}
        text={acfHome?.deerText}
      />
      <Location
        data={acfHome?.apartmentsSlide}
        title={acfHome?.locationTitle}
        subtitle={acfHome?.locationSubtitle}
        slider_title={acfHome?.aptsSliderTitle}
        slider_subtitle={acfHome?.aptsSliderSubtitle}
        tails={acfHome?.tails}
        setContactModalOpen={setContactModalOpen}
      />
      <Gallery
        gallery={acfHome?.gallery}
        construction_log={acfHome?.constructionLog}
        interiors={acfHome?.galleryInteriors}
      />
      <BusinessModel
        business_model_title={acfHome?.title}
        business_model_subtext={acfHome?.subtitle}
        business_model_text={acfHome?.text}
        business_model_items={acfHome?.features}
      />
      <Partners data={acfHome?.column} />
      <ContactSection
        search={search}
        investment={contact_modal_open?.investment}
        variant="home"
      />
      {contact_modal_open && (
        <ContactModal
          search={search}
          investment={contact_modal_open?.investment}
          onExit={() => setContactModalOpen(null)}
        />
      )}
    </Layout>
  );
};

export const query = graphql`
  query {
    homePage: allWpPage(filter: { id: { eq: "cG9zdDo5" } }) {
      nodes {
        acfHome {
          slider {
            heading
            text
            image {
              altText
              sourceUrl
            }
            imageMobile {
              altText
              sourceUrl
            }
          }
          popup {
            sourceUrl
          }
          aptsSliderSubtitle
          aptsSliderTitle
          apartmentsSlide {
            building
            floor
            name
            price
            rooms
            size
            pdfFile
            image {
              altText
              sourceUrl
            }
          }
          gallery {
            altText
            sourceUrl
          }
          galleryInteriors {
            altText
            sourceUrl
          }
          constructionLog {
            title
            dailyGallery {
              altText
              sourceUrl
            }
          }
          features {
            text
            icon {
              altText
              sourceUrl
            }
          }
          subtitle
          text
          title
          column {
            role
            name
            text
            signature {
              altText
              sourceUrl
            }
            logo {
              altText
              sourceUrl
            }
            partnerLink
          }
          locationSubtitle
          locationTitle
          tails {
            tailText
            tailTitle
            tailImage {
              altText
              sourceUrl
            }
          }
          prosTitle
          proText
          proTails {
            prosItemDesc
            prosItemImage {
              altText
              sourceUrl
            }
          }
          luxTitle
          luxText
          luxTails {
            luxItemDesc
            luxItemImage {
              altText
              sourceUrl
            }
          }
          investText1
          investTitle1
          investSubtitle1
          investTitle
          investText
          investSubtitle
          investImgRight {
            altText
            sourceUrl
          }
          investImgLeft {
            altText
            sourceUrl
          }
          deerLeftImage {
            sourceUrl
          }
          deerRightImage {
            sourceUrl
          }
          deerHeader
          deerText
        }
      }
    }
  }
`;

export default Home;
