import "./styles.scss";

import React, { useEffect, useState } from "react";
import Slider from "react-slick";

import Decor from "components/Icons/Decor";

import ArrowIcon from "assets/icons/arrow.svg";

const mainClass = "home-hero";

function CustomArrow(props) {
  const { className, onClick } = props;

  return <ArrowIcon className={className} onClick={onClick} />;
}

const Hero = ({ data }) => {
  const [window_width, setWindowWidth] = useState(null);

  const sliderSettings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    draggable: true,
    autoplay: true,
    autoplaySpeed: 6000,
    speed: 2000,
    useTransform: false,
    fade: true,
    nextArrow: (
      <CustomArrow className="home-hero__arrow home-hero__arrow--next" />
    ),
    prevArrow: (
      <CustomArrow className="home-hero__arrow home-hero__arrow--prev" />
    ),
  };

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    handleWindowResize();

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <section className={mainClass}>
      <div className="container">
        <Slider {...sliderSettings}>
          {data?.map(({ heading, text, image, imageMobile }, index) => {
            return (
              <div key={index}>
                <div
                  className={`${mainClass}__slide`}
                  style={{
                    backgroundImage: `url(${
                      window_width > 580
                        ? image?.sourceUrl
                        : !!imageMobile?.sourceUrl
                        ? imageMobile?.sourceUrl
                        : image?.sourceUrl
                    })`,
                  }}
                >
                  <div className={`${mainClass}__text`}>
                    <Decor />
                    <h1
                      dangerouslySetInnerHTML={{
                        __html: heading,
                      }}
                    />
                    <p className={`${mainClass}__subtitle`}>{text}</p>
                  </div>
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
    </section>
  );
};

export default Hero;
