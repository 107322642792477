import "./styles.scss";

import React from "react";
import Decor from "components/Icons/Decor";

const mainClass = "home-deer-design";

const DeerDesign = ({ left_image, right_image, header, text }) => {
  return (
    <section className={mainClass}>
      <div className="container-fluid">
        <div className={`${mainClass}__image`}>
          <img src={left_image} alt="" />
        </div>
        <div className={`${mainClass}__text`}>
          <h2
            className={`${mainClass}__text__title`}
            dangerouslySetInnerHTML={{
              __html: header,
            }}
          />
          <div className={`${mainClass}__text__decor`}>
            <Decor />
          </div>
          <h5
            dangerouslySetInnerHTML={{
              __html: text,
            }}
          />
          {/* Deer Design to lider na polskim rynku w kompleksowym wykończeniu
            wnętrz, który łączy pasję do projektowania z doświadczeniem w
            zakresie usług wykończeniowych */}

          <img
            src={require("assets/images/deer_design_logo.png").default}
            alt=""
          />
        </div>
        <div className={`${mainClass}__image`}>
          <img src={right_image} alt="" />
        </div>
      </div>
    </section>
  );
};

export default DeerDesign;
