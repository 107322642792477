import "./styles.scss";

import React from "react";

import FormContent from "components/Form";

import MessageIcon from "assets/icons/message.svg";

const mainClass = "home-fullfill-form";

const FulfillForm = ({ search, investment }) => {
  return (
    <section className={mainClass}>
      <div className={`${mainClass}__fullfill`}>
        <div id="zapytaj-section" className="container">
          <div className={`${mainClass}__header`}>
            <MessageIcon />
            <h3>
              Wypełnij formularz <br />
              <span>Dopasujemy ofertę do Twoich potrzeb</span>
            </h3>
          </div>
          <div className={`${mainClass}__content`}>
            <div className={`${mainClass}__content__wrapper`}>
              <FormContent search={search} investment={investment} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FulfillForm;
