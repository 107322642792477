import "./styles.scss";

import React, { useState, useRef } from "react";
import Slider from "react-slick";
import { Link } from "gatsby";

import Button from "components/Button";
import Decor from "components/Icons/Decor";

import ApartIcon from "assets/icons/apartments-icon.svg";
import PdfIcon from "assets/icons/pdf.svg";
import ArrowIcon from "assets/icons/arrow.svg";
import MessageIcon from "assets/icons/message.svg";

const mainClass = "home-location";

const CustomArrow = (props) => {
  const { className, onClick } = props;
  return (
    <button>
      <ArrowIcon className={className} onClick={onClick} />
    </button>
  );
};

const Location = ({
  data,
  title,
  subtitle,
  tails,
  setContactModalOpen,
  slider_title,
  slider_subtitle,
}) => {
  const slider = useRef(null);
  const [current_slide, setCurrentSlide] = useState(0);

  const slider_settings = {
    infinite: true,
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    draggable: true,
    centerMode: true,
    centerPadding: "400px",
    useTransform: false,
    beforeChange: (oldIndex, newIndex) => {
      setCurrentSlide(newIndex);
    },
    responsive: [
      {
        breakpoint: 1820,
        settings: {
          centerPadding: "300px",
        },
      },
      {
        breakpoint: 1640,
        settings: {
          centerPadding: "200px",
        },
      },
      {
        breakpoint: 1400,
        settings: {
          centerPadding: "100px",
        },
      },
      {
        breakpoint: 1140,
        settings: {
          centerPadding: "50px",
        },
      },
      {
        breakpoint: 1000,
        settings: {
          centerPadding: "0px",
        },
      },
    ],
  };

  return (
    <section id="lokalizacja-section" className={mainClass}>
      <div className={`${mainClass}__image ${mainClass}__image--1`}>
        <img src={require("assets/images/beach.png").default} alt="" />
      </div>
      <div className={`${mainClass}__image ${mainClass}__image--2`}>
        <img src={require("assets/images/sea_top.png").default} alt="" />
      </div>
      <div className={`${mainClass}__content`}>
        <div className="container">
          <div className={`${mainClass}__content--wrapper`}>
            <div className={`${mainClass}__location`}>
              <h2 className={`${mainClass}__location-title`}>{title}</h2>
              <div className={`${mainClass}__location-decor`}>
                <Decor />
              </div>
              <div
                dangerouslySetInnerHTML={{ __html: subtitle }}
                className={`${mainClass}__location-text`}
              />
              <div className={`${mainClass}__location-tails`}>
                {tails?.map(({ tailText, tailTitle, tailImage }, index) => {
                  return (
                    <div
                      key={index}
                      className={`${mainClass}__location-tail--wrapper`}
                    >
                      <div className={`${mainClass}__location-tail`}>
                        <div className={`${mainClass}__location-tail-img`}>
                          <img
                            src={tailImage?.sourceUrl}
                            alt={tailImage?.altText}
                          />
                        </div>
                        <div className={`${mainClass}__location-tail-content`}>
                          <div className={`${mainClass}__location-tail-bckg`}>
                            <img
                              src={
                                require("assets/images/location-tail-bckg.png")
                                  .default
                              }
                              alt=""
                            />
                          </div>
                          <div
                            className={`${mainClass}__location-tail-content--inner`}
                          >
                            <h4
                              dangerouslySetInnerHTML={{ __html: tailTitle }}
                              className={`${mainClass}__location-tail-title`}
                            />
                            <div
                              dangerouslySetInnerHTML={{ __html: tailText }}
                              className={`${mainClass}__location-tail-text`}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className={`${mainClass}__map`}>
              <img src={require("assets/images/map.png").default} alt="" />
            </div>
            <div className={`${mainClass}__divider`}>
              <div className={`${mainClass}__divider-icon`}>
                <ApartIcon />
              </div>
              <h2
                className={`${mainClass}__divider-title`}
                dangerouslySetInnerHTML={{
                  __html: slider_title,
                }}
              />
              <div className={`${mainClass}__divider-decor`}>
                <Decor />
              </div>
              <h3
                className={`${mainClass}__divider-subtitle`}
                dangerouslySetInnerHTML={{
                  __html: slider_subtitle,
                }}
              />
            </div>
          </div>
          <div className={`${mainClass}__cta`}>
            <Link
              to="/apartamenty/#aparments-list"
              className={`${mainClass}__cta-button`}
            >
              zobacz wszystkie
            </Link>
          </div>
        </div>
        <div className={`${mainClass}__slider--wrapper`}>
          <Slider
            ref={slider}
            className={`${mainClass}__slider`}
            {...slider_settings}
          >
            {data?.map(
              (
                { name, building, floor, rooms, size, price, image, pdfFile },
                index
              ) => (
                <div key={index}>
                  <div className={`${mainClass}__slider__item`}>
                    <div className={`${mainClass}__slider__item--top`}>
                      <div className={`${mainClass}__slider__item__info`}>
                        <p className={`${mainClass}__slider__item__name`}>
                          {name}
                        </p>
                        <div className={`${mainClass}__slider__item__details`}>
                          {building && (
                            <div className={`${mainClass}__slider__item__row`}>
                              <span>Budynek: </span>
                              <span>
                                <strong>{building}</strong>
                              </span>
                            </div>
                          )}
                          <div className={`${mainClass}__slider__item__row`}>
                            <span>Piętro: </span>
                            <span>
                              <strong>{floor || "0"}</strong>
                            </span>
                          </div>
                          <div className={`${mainClass}__slider__item__row`}>
                            <span>Liczba pokoi: </span>
                            <span>
                              <strong>{rooms}</strong>
                            </span>
                          </div>
                          <div className={`${mainClass}__slider__item__row`}>
                            <span>Powierzchnia: </span>
                            <span>
                              <strong>{size}</strong> m<sup>2</sup>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className={`${mainClass}__slider__item__plan`}>
                        <img src={image?.sourceUrl} alt="" />
                      </div>
                    </div>
                    <div className={`${mainClass}__slider__item--bottom`}>
                      {/* <div
                        className={`${mainClass}__slider__item__info__price`}
                      >
                        <span>Cena łączna netto:</span>
                        <span>
                          <strong>{price}</strong> zł
                        </span>
                      </div> */}
                      <div
                        className={`${mainClass}__slider__item__info__buttons`}
                      >
                        <div
                          className={`${mainClass}__slider__item__info__action`}
                        >
                          <Button
                            external
                            onClick={() => setContactModalOpen(true)}
                            icon={<MessageIcon />}
                          >
                            Zapytaj
                          </Button>
                        </div>
                        <div
                          className={`${mainClass}__slider__item__info__action`}
                        >
                          <Button external href={pdfFile} icon={<PdfIcon />}>
                            Pobierz rzut
                          </Button>
                        </div>
                      </div>
                    </div>

                    {current_slide === index && (
                      <>
                        <CustomArrow
                          className={`${mainClass}__slider__item__arrow ${mainClass}__slider__item__arrow--prev`}
                          onClick={() => slider?.current?.slickPrev()}
                        />
                        <CustomArrow
                          className={`${mainClass}__slider__item__arrow ${mainClass}__slider__item__arrow--next`}
                          onClick={() => slider?.current?.slickNext()}
                        />
                      </>
                    )}
                  </div>
                </div>
              )
            )}
          </Slider>
        </div>
      </div>
    </section>
  );
};

export default Location;
