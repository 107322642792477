import "./styles.scss";

import React from "react";

const mainClass = "home-luxury";

const Luxury = ({ data }) => {
  return (
    <section className={mainClass}>
      <div className={`${mainClass}__image`}>
        <img src={require("assets/images/luxury-bckg.jpg").default} alt="" />
      </div>
      <div className={`${mainClass}__content`}>
        <div className="container">
          <div className={`${mainClass}__content--wrapper`}>
            <h2 className={`${mainClass}__sign`}>Luksus</h2>
            <div className={`${mainClass}__features`}>
              {data?.map(({ luxItemImage, luxItemDesc }, index) => {
                return (
                  <div key={index} className={`${mainClass}__features-item`}>
                    <img
                      src={luxItemImage?.sourceUrl}
                      alt={luxItemImage?.alttext}
                      className={`${mainClass}__features-icon`}
                    />
                    <p className={`${mainClass}__features-title`}>
                      {luxItemDesc}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Luxury;
