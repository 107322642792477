import "./styles.scss";

import React from "react";

const mainClass = "home-stars";

const CityOfStars = ({ title, text }) => {
  return (
    <section className={mainClass}>
      <div className={`${mainClass}__image`}>
        <img src={require("assets/images/invest-bckg.jpg").default} alt="" />
      </div>
      <div className={`${mainClass}__logo`}>
        <img src={require("assets/logo-white.svg").default} alt="" />
      </div>
      <div className={`${mainClass}__content`}>
        <div className="container">
          <div className={`${mainClass}__content--wrapper`}>
            <h2
              className={`${mainClass}__content-title`}
              dangerouslySetInnerHTML={{
                __html: title,
              }}
            />
            <p
              className={`${mainClass}__content-text`}
              dangerouslySetInnerHTML={{
                __html: text,
              }}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default CityOfStars;
