import "./styles.scss";

import React from "react";

import TooltipPointer from "assets/icons/tooltip_building.svg";

const mainClass = "pros-tooltips";

const ProsTooltips = ({ data }) => {
  return (
    <section className={mainClass}>
      <div className={`${mainClass}__image`}>
        <img src={require("assets/images/pros-bckg.jpg").default} alt="" />
      </div>
      {data.map(({ prosItemDesc, prosItemImage }, index) => {
        return (
          <div key={index} className={`${mainClass}__tooltip`}>
            <div className={`${mainClass}__tooltip-icon`}>
              <TooltipPointer />
            </div>
            <div className={`${mainClass}__tooltip-content`}>
              <div className={`${mainClass}__tooltip-image`}>
                <img src={prosItemImage?.sourceUrl} alt="" />
              </div>
              <p className={`${mainClass}__tooltip-title`}>{prosItemDesc}</p>
            </div>
          </div>
        );
      })}
    </section>
  );
};

export default ProsTooltips;
