import "./styles.scss";

import React, { useEffect, useState } from "react";
import Slider from "react-slick";

import VistaLogo from "assets/images/logos/logo-vista.png";
import BergoLogo from "assets/images/logos/bergo-logo.png";
import TremontiLogo from "assets/images/logos/tremonti-szklarska-poreba.png";
import TremontiResortLogo from "assets/images/logos/tremonti-resort.png";
import TremontiHotelLogo from "assets/images/logos/tremonti-hotel.png";

const mainClass = "home-partners";

const slider_settings = {
  infinite: true,
  slidesToShow: 2,
  slidesToScroll: 1,
  arrows: false,
  draggable: true,
  autoplay: true,
  speed: 2000,
  autoplaySpeed: 2000,
  responsive: [
    {
      breakpoint: 420,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

const Partners = ({ data }) => {
  const [position, setPosition] = useState("0");

  useEffect(() => {
    window.addEventListener("scroll", () => {
      const section = document.getElementsByClassName(mainClass)[0];
      const hands_image = document.getElementsByClassName(
        `${mainClass}__image`
      )[0];
      const window_width = window.innerWidth;
      let multiplier = 7;
      let compensation = 1;

      if (window_width < 1100) {
        multiplier = 6;
        compensation = 2;
      }
      if (window_width < 500) {
        multiplier = 5;
        compensation = 2.5;
      }

      setPosition(
        `${
          ((section?.offsetTop - window?.pageYOffset) /
            (hands_image?.offsetHeight * compensation)) *
          multiplier
        }%`
      );
    });
  }, []);

  return (
    <section id="inwestor-section" className={mainClass}>
      <h2 className={`${mainClass}__heading`}>
        Doświadczeni
        <br />
        <span>i pewni partnerzy</span>
      </h2>
      <div
        className={`${mainClass}__image`}
        style={{ transform: `translateX(${position})` }}
      >
        <img src={require("assets/images/hands.png").default} alt="" />
      </div>
      <div className="container">
        <div className={`${mainClass}__content`}>
          {/* {data?.map(({ role, name, text, signature, logo }, index) => {
            return ( */}
          {/* <div className={`${mainClass}__column`}>
            <h4 className={`${mainClass}__role`}>{data?.[0]?.role}</h4>
            <h5 className={`${mainClass}__name`}>{data?.[0]?.name}</h5>
            <a
              href={data?.[0]?.partnerLink}
              target="_blank"
              rel="noreferrer"
              className={`${mainClass}__logo`}
            >
              <img src={data?.[0]?.logo?.sourceUrl} alt="" />
            </a>
            <div className={`${mainClass}__signature`}>
              {data?.[0]?.signature && (
                <img src={data?.[0]?.signature?.sourceUrl} alt="" />
              )}
            </div>
            <div className={`${mainClass}__investments`}>
              <h5>Obsługiwane inwestycje</h5>
              <Slider {...slider_settings}>
                <div>
                  <img src={VistaLogo} alt="" />
                </div>
                <div>
                  <img src={BergoLogo} alt="" />
                </div>
                <div>
                  <img src={TremontiLogo} alt="" />
                </div>
                <div>
                  <img src={TremontiResortLogo} alt="" />
                </div>
                <div>
                  <img src={TremontiHotelLogo} alt="" />
                </div>
              </Slider>
            </div>
            <span
              className={`${mainClass}__text`}
              dangerouslySetInnerHTML={{
                __html: data?.[0]?.text,
              }}
            />
          </div> */}
          <div className={`${mainClass}__column ${mainClass}__column--single`}>
            <h4 className={`${mainClass}__role`}>{data?.[1]?.role}</h4>
            <div className={`${mainClass}__investors`}>
              <div className={`${mainClass}__col-left`}>
                <h5 className={`${mainClass}__name`}>{data?.[1]?.name}</h5>
                <a
                  href={data?.[1]?.partnerLink}
                  className={`${mainClass}__logo`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={data?.[1]?.logo?.sourceUrl} alt="" />
                </a>
              </div>
              <div className={`${mainClass}__col-right`}>
                <h5 className={`${mainClass}__name`}>{data?.[2]?.name}</h5>
                <a
                  href={data?.[2]?.partnerLink}
                  className={`${mainClass}__logo`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={data?.[2]?.logo?.sourceUrl} alt="" />
                </a>
              </div>
            </div>
            <div className={`${mainClass}__signature`}>
              {data?.[1]?.signature && (
                <img src={data?.[1]?.signature?.sourceUrl} alt="" />
              )}
            </div>
            <span
              className={`${mainClass}__text`}
              dangerouslySetInnerHTML={{
                __html: data?.[1]?.text,
              }}
            />
            <div className={`${mainClass}__logo`} style={{ marginTop: 40 }}>
              {data?.[3]?.logo?.sourceUrl && (
                <img src={data?.[3]?.logo?.sourceUrl} alt="" />
              )}
            </div>
          </div>
          {/* );
          })} */}
        </div>
      </div>
    </section>
  );
};

export default Partners;
