import "./styles.scss";

import React from "react";
import { Link } from "gatsby";

import Button from "components/Button";
import Decor from "components/Icons/Decor";
import SearchBar from "components/SearchBar";

import ApartIcon from "assets/icons/apartments-icon.svg";
import WalletIcon from "assets/icons/wallet.svg";

const mainClass = "home-forest-view";

const ForestView = ({ data, location }) => {
  const {
    investText1,
    investTitle1,
    investSubtitle1,
    investTitle,
    investSubtitle,
    investText,
    investImgLeft,
    investImgRight,
  } = data;
  return (
    <section id="o-inwestycji-section" className={mainClass}>
      <SearchBar location={location} />
      <div className={`${mainClass}__wrapper`}>
        <div className={`${mainClass}__heading`}>
          <h2
            className={`${mainClass}__heading--first`}
            dangerouslySetInnerHTML={{
              __html: investTitle1,
            }}
          />
          <Decor />
          <span
            className={`${mainClass}__heading--subtitle`}
            dangerouslySetInnerHTML={{
              __html: investSubtitle1,
            }}
          />
          <span
            className={`${mainClass}__heading--desc`}
            dangerouslySetInnerHTML={{
              __html: investText1,
            }}
          />
        </div>
        <div className={`${mainClass}__image`}>
          <img src={require("assets/images/building.png").default} alt="" />
        </div>
        <div className={`${mainClass}__invest`}>
          <div className="container">
            <div className={`${mainClass}__invest--wrapper`}>
              <div
                className={`${mainClass}__invest--photo ${mainClass}__invest--photo--1`}
              >
                <img
                  src={investImgLeft?.sourceUrl}
                  alt={investImgLeft?.altText}
                />
              </div>
              <div className={`${mainClass}__invest--content`}>
                <WalletIcon />
                <h2
                  className={`${mainClass}__invest--first`}
                  dangerouslySetInnerHTML={{
                    __html: investTitle,
                  }}
                />
                <span
                  className={`${mainClass}__invest--second`}
                  dangerouslySetInnerHTML={{
                    __html: investSubtitle,
                  }}
                />
                <span
                  className={`${mainClass}__invest--desc`}
                  dangerouslySetInnerHTML={{
                    __html: investText,
                  }}
                />

                <Link
                  to="/apartamenty/#searchbar-apartments"
                  className={`${mainClass}__invest--btn`}
                >
                  <Button icon={<ApartIcon />}>Wybierz apartament</Button>
                </Link>
              </div>
              <div className={`${mainClass}__invest--photo-bottom`}>
                <div
                  className={`${mainClass}__invest--photo ${mainClass}__invest--photo--2`}
                >
                  <img
                    src={investImgRight?.sourceUrl}
                    alt={investImgRight?.altText}
                  />
                </div>
                <div
                  className={`${mainClass}__invest--photo ${mainClass}__invest--photo--3`}
                >
                  <img
                    src={investImgLeft?.sourceUrl}
                    alt={investImgLeft?.altText}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ForestView;
