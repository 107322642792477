import "./styles.scss";

import React, { useState } from "react";

import GallerySlider from "./Slider";
import Daily from "./Daily";
import Interiors from "./Interiors";
import Button from "components/Button";

// import GalleryIcon from "assets/icons/gallery_icon_1.svg";
// import GalleryIconTwo from "assets/icons/gallery_icon_2.svg";

const mainClass = "home-gallery";

const Gallery = ({ construction_log, gallery, interiors }) => {
  const [view, setView] = useState(0);

  const getGalleryByView = (view) => {
    switch (view) {
      case 0:
        return <GallerySlider items={gallery} />;
      case 1:
        return <Daily items={construction_log} />;
      case 2:
        return <Interiors items={interiors} />;
      default:
        return <GallerySlider items={gallery} />;
    }
  };

  const chosen_gallery = getGalleryByView(view);

  return (
    <section id="galeria-section" className={mainClass}>
      <div className={`${mainClass}__content`}>
        <div className="container">
          <div className={`${mainClass}__content--wrapper`}>
            <h2 className={`${mainClass}__header`}>Galeria </h2>
            <div className={`${mainClass}__buttons`}>
              <Button
                // icon={<GalleryIcon />}
                active={view === 0}
                onClick={() => setView(0)}
              >
                Inwestycja
              </Button>
              <Button
                // icon={<GalleryIcon />}
                active={view === 2}
                onClick={() => setView(2)}
              >
                Wnętrza
              </Button>
              <Button
                // icon={<GalleryIconTwo />}
                active={view === 1}
                onClick={() => setView(1)}
              >
                Dziennik budowy
              </Button>
            </div>
          </div>
        </div>
        {chosen_gallery}
      </div>
    </section>
  );
};

export default Gallery;
